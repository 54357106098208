import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDu5dx0WQ6P0sY57BELBWnQMjEvQgUuCuk",
  authDomain: "telimpromptu-backend.firebaseapp.com",
  projectId: "telimpromptu-backend",
  storageBucket: "telimpromptu-backend.appspot.com",
  messagingSenderId: "52282953614",
  appId: "1:52282953614:web:b9ee965ad53e4f7d9a1edf",
  measurementId: "G-KZTHQWY7Q5"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const roomsCollectionRef = collection(db, "rooms");
export const responseCollectionRef = collection(db, "responses");
export const playerCollectionRef = collection(db, "players");
export const PROJECT_ID = "telimpromptu-backend";
export const REGION = 'us-central1';